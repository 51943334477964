import { BoxArrowDown, ClockCounterClockwise, Play, Trash } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Button } from "semantic-ui-react";
import ActivityDeleteDialog from "../components/ActivityDeleteDialog";
import { ActivityDescription, ActivitySkills, ActivityTasks } from "../components/ActivityDetails";
import ButtonBar from "../components/ButtonBar";
import { EmptyState } from "../components/EmptyState";
import LoadableButton from "../components/LoadableButton";
import { ZataLoader } from "../components/ZataLoader";
import useAPI from "../hooks/useAPI";
import useAuth from "../hooks/useAuth";
import { useLanguage } from "../hooks/useLanguage";
import useNotifications from "../hooks/useNotifications";
import useTask from "../hooks/useTask";
import useTitle from "../hooks/useTitle";
import { toCoolEmojis } from "../utils/emoji.utils";
import { strings } from "../utils/i18n.utils";

export const ActivityScreen = () => {
    const notFoundErrorHandler = (error) => {
        if (error.status === 404) {
            return;
        }
        addNotification("api", strings.error_api, error.message);
    };
    const { user, currentSpace } = useAuth();
    const [{ response, error }, callAPI] = useAPI();
    const [deleteAPI, callDeleteAPI] = useAPI({ loading: false });
    const [updateAPI, callUpdateAPI] = useAPI({ loading: false });

    const { addNotification } = useNotifications();
    const [activity, setActivity] = useState(null);
    const history = useHistory();
    const { activityId } = useParams();
    const { language, nativeLanguage, setLanguage } = useLanguage();
    const [taskResult] = useTask(response?.task_id);
    const { setPageTitle, setDescription, setNavigationTitle } = useTitle();

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    function archiveActivity() {
        callDeleteAPI("PUT", `/api/activities/${activity.id}`, {
            archived: !activity.archived,
            unarchive_activity_collection: activity.archived,
        });
        setActivity({
            ...activity,
            archived: !activity.archived,
        });
    }

    useEffect(() => {
        if (response) {
            setActivity(response);
        }
    }, [response]);

    useEffect(() => {
        callAPI(
            "GET",
            `/api/activities/${activityId}?include_progress=True&native_language=${nativeLanguage}&include_vocab=True&include_practice_statistics=True`,
            null,
            null,
            notFoundErrorHandler
        );
    }, [activityId, nativeLanguage]);

    useEffect(() => {
        if (taskResult?.task) {
            if (taskResult.task.status === "COMPLETED") {
                callAPI(
                    "GET",
                    `/api/activities/${activityId}?include_progress=True&native_language=${nativeLanguage}&include_vocab=True&include_practice_statistics=True`
                );
            } else {
                setActivity(taskResult.result);
            }
        }
    }, [taskResult]);

    useEffect(() => {
        if (activity) {
            setPageTitle(activity.name_native || activity.name);

            if (activity.type === "role_play") {
                setDescription(activity.scenario_description_native);
            } else if (activity.type === "discuss") {
                // join the response.activity_tasks into a string with commas in between
                let description = "";
                for (let i = 0; i < activity.activity_tasks.length; i++) {
                    description += activity.activity_tasks[i].description_native;
                    if (i < activity.activity_tasks.length - 1) {
                        description += ", ";
                    }
                }
                setDescription(description);
            }
        }
    }, [activity]);

    const updateActivityCollection = (activityCollection) => {
        callUpdateAPI("PUT", `/api/activities/${activity.id}`, { activity_collection_id: activityCollection.id });
        setActivity({
            ...activity,
            activity_collection_id: activityCollection.id,
            activity_collection: activityCollection,
        });
    };

    const redirectAfterDelete = () => {
        history.push(`/spaces/${currentSpace.id}/home`);
    };

    if (!error && !activity) return <ZataLoader message={strings.loading} />;

    if (error && error.status === 404) {
        return (
            <>
                <EmptyState
                    message={
                        <>
                            <h1>{strings.activity_not_found}</h1> <p>{strings.activity_not_found_details}</p>
                        </>
                    }
                />
            </>
        );
    }

    if (!response) {
        return <div />;
    }

    return (
        <div>
            <h1>
                {toCoolEmojis(activity.emoji)} {activity.name_native || activity.name}
            </h1>
            {activity.name_native && <p>{activity.name}</p>}

            <ButtonBar multirow={true}>
                <Button
                    icon={<Play />}
                    content={strings.activity_start}
                    primary
                    compact
                    as={Link}
                    to={`/spaces/${currentSpace?.id}/chat?activityId=${response.id}`}
                />

                {user && activity.user_id === user.id && (
                    <>
                        {activity.archived && (
                            <LoadableButton
                                loading={deleteAPI.loading}
                                icon={<ClockCounterClockwise />}
                                content={strings.restore}
                                compact
                                onClick={archiveActivity}
                            />
                        )}

                        {activity.archived && (
                            <Button
                                icon={<Trash />}
                                content={strings.delete}
                                compact
                                onClick={() => setShowDeleteDialog(true)}
                            />
                        )}

                        {!activity.archived && (
                            <LoadableButton
                                loading={deleteAPI.loading}
                                icon={<BoxArrowDown />}
                                content={strings.archive}
                                compact
                                onClick={archiveActivity}
                            />
                        )}
                    </>
                )}
            </ButtonBar>

            {activity.task_id && <ZataLoader />}
            {!activity.task_id && (
                <div>
                    {["discuss", "role_play", "tutor"].includes(activity.type) && (
                        <div>
                            <h2>{strings.tasks}</h2>
                            <ActivityDescription activity={activity} user={user} setActivity={setActivity} />
                            <ActivityTasks tasks={activity.activity_tasks} />
                        </div>
                    )}
                    {activity.skills && activity.skills.length > 0 && (
                        <div>
                            <h2>{strings.skills}</h2>
                            <ActivitySkills skills={activity.skills} />
                        </div>
                    )}
                </div>
            )}

            <ActivityDeleteDialog
                activity={activity}
                setOpen={setShowDeleteDialog}
                isOpen={showDeleteDialog}
                onDelete={redirectAfterDelete}
            />
        </div>
    );
};
